<template>
	<el-dialog v-dialogDrag title="新增模板" :visible.sync="visible" width="600px" :before-close="close">
		<el-form ref="form" label-width="100px" :rules="rules" :model="formParam">
			<el-form-item label="类型名称" prop="templateTypeId">
				<el-select v-model="formParam.templateTypeId" placeholder="请选择类型名称">
					<el-option v-for="item in data" :key="item.id" :label="item.typeName" :value="item.id">
					</el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="模板名称" prop="tempName">
				<el-input v-model="formParam.tempName" placeholder="请输入模板名称" maxLength="15"></el-input>
			</el-form-item>
			<el-form-item label="描述" prop="tempDesc">
				<el-input v-model="formParam.tempDesc" maxLength="100" placeholder="请输入描述" type="textarea" row="3">
				</el-input>
			</el-form-item>
			<el-form-item label="模板状态" prop="tempStatus">
				<el-radio-group v-model="formParam.tempStatus">
					<el-radio :label="-1">草稿</el-radio>
					<el-radio :label="0">停用</el-radio>
					<el-radio :label="1">启用</el-radio>
				</el-radio-group>
				</el-input>
			</el-form-item>
		</el-form>
		<span slot="footer" class="dialog-footer">
			<el-button size="small" @click="close">取 消</el-button>
			<el-button size="small" type="primary" @click="submit" :loading="btnLoading">确 定</el-button>
		</span>
	</el-dialog>
</template>

<script>
	export default {
		data() {
			return {
				formParam: {
					tempStatus: 1
				},
				btnLoading: false,
				rules: {
					templateTypeId: [{
						required: true,
						message: '请选择类型名称',
						trigger: 'blur'
					}],
					tempName: [{
						required: true,
						message: '请输入模板名称',
						trigger: 'blur'
					}]
				}
			}
		},
		props: {
			show: {
				type: Boolean,
				default: false,
			},
			data: {
				type: Array,
				default: () => {
					return []
				}
			},
			id: {
				type: Number | String,
				default: null
			}
		},
		computed: {
			visible: {
				get() {
					if (this.id) {
						this.getDetail()
					} else {
						this.formParam = {
							tempStatus: 1,
							templateTypeId:this.$parent.pageParam.templateTypeId,
						}
					}
					return this.show;
				},
				set(val) {
					this.$emit("update:show", val);
				},
			},
		},
		watch: {},
		created() {},
		mounted() {},
		methods: {
			// 关闭
			close() {
				this.$refs.form.resetFields();
				this.$emit('update:show', false)
			},
			// 获取详情
			async getDetail() {
				let res = await this.$get(`/platform-config/templateinfo/${this.id}`);
				if (res && res.code == 0) {
					this.formParam = res.data
				}
			},
			// 确定提交
			submit() {
				this.$refs.form.validate((valid) => {
					if (valid) {
						this.btnLoading = true
						if (this.id) {
							this.update()
						} else {
							this.save()
						}
					}
				});
			},
			// 新增
			async save() {
				let res = await this.$post('/platform-config/templateinfo', this.formParam);
				if (res && res.code == 0) {
					this.$message.success("新增成功！")
					this.btnLoading = false;
					this.$emit('update:show', false)
					this.$parent.getList()
				}
			},
			// 编辑
			async update() {
				let res = await this.$put('/platform-config/templateinfo', this.formParam);
				if (res && res.code == 0) {
					this.$message.success("修改成功！")
					this.btnLoading = false;
					this.$emit('update:show', false)
					this.$parent.getList()
				}
			},
		},
	};
</script>

<style scoped>
	.editor {
		width: 100%;
		height: 350px;
	}
</style>
