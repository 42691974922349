<template>
	<div class="warp">
		<div class="left">
			<div class="btnBox">
				<el-button class="add" type="primary" icon="el-icon-circle-plus" size="small" v-if="isAuth('platform-config:tbtemplatetype:save')" @click="openType()">新增类型</el-button>
			</div>
			<div class="tree-container">
				<div class="all" @click="activeFun()" :class="{active:active==-1}">全部</div>
				<div v-for="(v,i) in data" :key="'data'+i" @click="activeFun(v)" class="flex_bet" :class="{active:active==v.id}">
					<div>{{v.typeName}}</div>
					<div>
						<img @click.stop="openType(v)" v-if="isAuth('platform-config:tbtemplatetype:update')" src="@/assets/img/icon/icon_edit.png" alt="">
						<img @click.stop="del(v)" v-if="isAuth('platform-config:tbtemplatetype:delete')" class="ml10" src="@/assets/img/icon/icon_delete.png" alt="">
					</div>
				</div>
				<div class="noData" v-if="data.length<=0">暂无数据</div>
			</div>
		</div>
		<div class="right">
			<jf-layout>
				<template slot="head">
					<el-form :inline="true" ref="pageParam">
						<el-form-item>
							<el-select size="small" v-model="pageParam.tempStatus" clearable placeholder="请选择">
								<el-option label="启用" :value="1"></el-option>
								<el-option label="草稿" :value="-1"></el-option>
								<el-option label="停用" :value="0"></el-option>
							</el-select>
						</el-form-item>
						<el-form-item>
							<el-input v-model="pageParam.tempName" clearable placeholder="请输入模板名称" size="small">
							</el-input>
						</el-form-item>
						<el-form-item>
							<el-button size="small" type="primary" icon="el-icon-search" @click="getList('reset')">查询
							</el-button>
						</el-form-item>
						<el-form-item>
							<el-button size="small" type="primary" v-if="isAuth('platform-config:templateinfo:save')" icon="el-icon-circle-plus"
								@click="openAddTemplate()">新增模板</el-button>
						</el-form-item>
					</el-form>
					<div class="hint">
						<div>已选中<span class="c-warning">{{selectArr.length}}</span>条</div>
						<div>
							<el-button size="mini" type="danger" icon="el-icon-delete" v-if="isAuth('platform-config:templateinfo:delete')" :disabled="selectArr.length<=0"
								@click="delRow()">删除</el-button>
						</div>
					</div>
				</template>
				<el-table slot="body" border :data="list" v-loading="tableLoading" @selection-change="getSelect">
					<el-table-column align="center" type="selection" width="50" />
					<el-table-column align="center"  show-overflow-tooltip label="模板名称">
						<template slot-scope="{row}">
							<div class="c-theme pointer" @click="openDetail(row)">{{row.tempName}}</div>
						</template>
					</el-table-column>
					<el-table-column align="center" prop="tempDesc" show-overflow-tooltip label="模板描述" />
					<el-table-column align="center" prop="realName" label="创建人" />
					<el-table-column align="center" prop="createDate" label="创建时间" />
					<el-table-column align="center" prop="tempStatus" label="状态" width="100">
						<template slot-scope="{row}">
							<el-tag type="success" v-if="row.tempStatus==1">启用</el-tag>
							<el-tag type="info" v-if="row.tempStatus==-1">草稿</el-tag>
							<el-tag type="warning" v-if="row.tempStatus==0">停用</el-tag>
						</template>
					</el-table-column>
					<el-table-column align="center" label="操作" width="300">
						<template slot-scope="{row}">
							<el-button type="text" size="mini" icon="el-icon-s-tools" @click="goEvent(row)">事件配置
							</el-button>
							<el-button type="text" size="mini" icon="el-icon-edit" @click="openAddTemplate(row)">编辑
							</el-button>
							<el-button type="text" class="c-danger-imp" size="mini" icon="el-icon-delete"
								@click="delRow(row)">删除</el-button>
						</template>
					</el-table-column>

				</el-table>
				<jf-page slot="foot" :total="total" :page.sync="pageParam.page" :limit.sync="pageParam.limit"  @change="getList('reset')"/>

			</jf-layout>
			<AddType :show.sync="addTypeShow" :data="data" :value="typeVal"/>
			<AddTemplate :show.sync="addTemplateShow" :data="data" :id="id" />
			<detail :show.sync="detailShow" :id="id"></detail>
		</div>
	</div>
</template>
<script>
	import AddType from "./addType";
	import AddTemplate from "./addTemplate";
	import detail from "./detail";
	export default {
		components: {
			AddType,
			AddTemplate,
			detail
		},
		data() {
			return {
				addTypeShow: false,
				addTemplateShow: false,
				detailShow: false, //查看模板所有事件弹窗
				id: null,
				active: null,
				selectArr: [],
				pageParam: {
					page: 1,
					limit: 10
				},
				tableLoading: false,
				total: 0,
				data: [],
				list: [],
				typeVal:{},
			};
		},
		created() {
			this.getTree();
		},
		methods: {
			// 打开类型编辑弹窗
			openType(data){
				this.addTypeShow = true
				if(data){
					this.typeVal=data;
				}
			},
			// 获取tree
			async getTree() {
				let res = await this.$get("/platform-config/templatetype/page");
				if (res && res.code == 0) {
					this.data = res.data.list;
					this.getList()
				}
			},
			//类型选中
			activeFun(v) {
				if(v){
					this.active = v.id;
					this.pageParam.templateTypeId = v.id
				}else{
					this.active = -1;
					this.pageParam.templateTypeId = null
				}
				this.getList()
			},
			// 选择
			getSelect(e) {
				this.selectArr = e
			},
			// 打开 新增 修改 模板弹窗
			openAddTemplate(row) {
				this.addTemplateShow = true;
				this.id = row ? row.id : null;
			},
			// 打开查看弹窗
			openDetail(row) {
				this.id=row ? row.id : null
				this.detailShow = true
			},
			// 跳转事件库模板
			goEvent(row) {
				this.$router.push({
					path: "/setup-template-event",
					query: {
						id: row.id,
					}
				})
			},
			// 获取列表
			async getList(reset) {
				this.tableLoading = true;
				if (reset) {
					this.pageParam.page = 1
				}
				let res = await this.$get('/platform-config/templateinfo/page', this.pageParam);
				this.tableLoading = false;
				if (res && res.code == 0) {
					this.list = res.data.list;
					this.total = res.data.total;
				}
			},
			// 删除
			delRow(row) {
				let ids = []
				if (row) {
					ids = [row.id]
				} else {
					this.selectArr.map((v) => {
						ids.push(v.id)
					})
				}
				this.$confirm('确定执行删除操作?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(async () => {
					this.tableLoading = true;
					let res = await this.$del('/platform-config/templateinfo', ids);
					this.tableLoading = false;
					if (res && res.code == 0) {
						this.$message.success("删除成功！")
						this.getList()
					}
				}).catch(() => {});
			},
			// 删除类型
			del(data){
				this.$confirm('确定执行删除操作?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(async () => {
					let res=await this.$del(`/platform-config/templatetype`,[data.id]);
					if(res&&res.code==0){
						this.$message.success("删除成功")
						this.getTree()
					}
				}).catch(() => {});
			},
			
		},
	};
</script>

<style scoped lang="scss">
	.warp {
		width: 100%;
		height: 100%;
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		right: 0;
		display: flex;

		.left {
			width: 300px;
			min-width: 300px;
			border-right: 1px solid #eee;
			padding: 20px;
			.btnBox{
				text-align: center;
			}
			.add {
				margin-top: 3px;
			}

			.title {
				margin: 0 0 0 0;
				font-size: 16px;
				margin-top: 15px;
				padding-left: 15px;
			}

			>ul {
				list-style: none;
				padding: 0 0 0 0px;

				li {
					padding-left: 15px;
					line-height: 36px;
					border-bottom: 1px solid #eee;

					&:hover {
						cursor: pointer;
					}

					&.active {
						background: rgb(223, 239, 255);
					}

					span {
						float: right;

						i {
							margin: 0 3px;

							&:hover {
								opacity: 0.7;
							}
						}

						.el-icon-edit {
							color: blue;
						}

						.el-icon-delete {
							color: red;
						}
					}
				}
			}
		}

		.right {
			flex: 1;
			padding: 20px;
		}
	}

	.tree-container {
		margin-top: 20px;
		overflow: hidden;

		>div {
			width: 100%;
			height: 40px;
			line-height: 40px;
			font-size: 14px;
			color: #333333;
			cursor: pointer;
			padding-left: 30px;
			border-bottom: 1px solid #eee;
		}

		.active {
			color: black;
			background: #EAF6FF;
			color: #34A2F1;
		}
	}

	.tree /deep/ .el-tree-node {
		position: relative;
		padding-left: 0;
	}

	.tree /deep/ .el-tree-node__children {
		padding-left: 16px;
	}

	.tree /deep/ .el-tree-node :last-child:before {
		height: 12px;
	}

	.tree /deep/ .el-tree>.el-tree-node:before {
		border-left: none;
	}

	.tree-container /deep/ .el-tree>.el-tree-node:after {
		border-top: none;
	}

	.tree /deep/ .el-tree-node:before {
		content: "";
		left: -4px;
		position: absolute;
		right: auto;
		border-width: 1px;
		border-left: 1px dashed #999;
		bottom: 0px;
		height: 100%;
		top: 0px;
		width: 1px;
	}

	.tree /deep/ .el-tree-node:after {
		content: "";
		left: -4px;
		position: absolute;
		right: auto;
		border-width: 1px;
		border-top: 1px dashed #999;
		height: 20px;
		top: 12px;
		width: 10px;
	}

	.noData {
		border: none !important;
		text-align: center;
		color: #ccc;
	}

	.hint {
		display: flex;
		justify-content: space-between;
		background: #FFF4E3;
		border-radius: 5px;
		padding: 0 17px;
	}
	.ml10{
		margin-left: 10px;
	}
</style>
